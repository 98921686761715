import { FC } from 'react'

export const LinkToTariff: FC = () => {
    return (
        <a
            href="https://www.masterchain.ru/disclosure/tarify/"
            target="_blank"
            rel="noreferrer noopener"
        >
            Тарифам Оператора ИС
        </a>
    )
}
